import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/(landing)": [23,[6]],
		"/(dashboard)/dash/(application)": [8,[2,3]],
		"/(dashboard)/dash/(application)/billing": [18,[2,3]],
		"/(dashboard)/dash/(application)/organization": [19,[2,3]],
		"/(dashboard)/dash/(application)/profile": [20,[2,3]],
		"/(dashboard)/dash/(standalone)/signin": [21,[2,5]],
		"/(dashboard)/dash/(standalone)/signin/[provider]/end": [22,[2,5]],
		"/(dashboard)/dash/(application)/[productID]/feedbacks": [9,[2,3,4]],
		"/(dashboard)/dash/(application)/[productID]/feedbacks/[reviewID]": [10,[2,3,4]],
		"/(dashboard)/dash/(application)/[productID]/issues": [11,[2,3,4]],
		"/(dashboard)/dash/(application)/[productID]/issues/[issueID]": [12,[2,3,4]],
		"/(dashboard)/dash/(application)/[productID]/overview": [13,[2,3,4]],
		"/(dashboard)/dash/(application)/[productID]/personas/[[personaID]]": [14,[2,3,4]],
		"/(dashboard)/dash/(application)/[productID]/settings": [15,[2,3,4]],
		"/(dashboard)/dash/(application)/[productID]/suggestions": [16,[2,3,4]],
		"/(dashboard)/dash/(application)/[productID]/suggestions/[suggestionID]": [17,[2,3,4]],
		"/(landing)/integrations": [24,[6]],
		"/(landing)/privacy": [25,[6]],
		"/(landing)/terms": [26,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';